<template>
	<div id="app">
		<NavBar id="tmtNavBar" :logo="logo" :navs="navs"></NavBar>
		<router-view
			class="col-12 col-md-11 col-lg-10 col-xl-8 mx-auto mt-3 content-view"
		/>
		<Footer id="tmtFooter"></Footer>
	</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
	components: {
		NavBar,
		Footer,
	},
	data() {
		return {
			logo: {
				link: '/',
				alt: 'tomatoTEK logo',
			},
			navs: [
				{
					id: 'News',
					type: 'link',
					text: '最新消息',
					subText: 'NEWS',
					link: '/news',
					value: [],
				},
				{
					id: 'Solution',
					type: 'dropdown',
					text: '解決方案',
					subText: 'SOLUTION',
					link: '#',
					value: [
						{
							id: 'SolutionItem1',
							text: '中央空調數據神經系統',
							link: '/solution/winds-hvac',
						},
						{
							id: 'SolutionItem2',
							text: '微氣候能量AI執行系統',
							link: '/solution/aies-hvac',
						},
					],
				},
				{
					id: 'Case',
					type: 'dropdown',
					text: '成功案例',
					subText: 'CASE STUDY',
					link: '#',
					value: [
						{
							id: 'CaseItem1',
							text: '中央空調數據神經系統案例',
							link: '/case/winds-hvac',
						},
						{
							id: 'CaseItem2',
							text: '微氣候能量AI執行系統案例',
							link: '/case/aies-hvac',
						},
					],
				},
				{
					id: 'About',
					type: 'dropdown',
					text: '關於我們',
					subText: 'ABOUT US',
					link: '#',
					value: [
						{
							id: 'AboutItem1',
							text: '公司沿革',
							link: '/about/history',
						},
						{
							id: 'AboutItem2',
							text: '技術專家團隊',
							link: '/about/team',
						},
						{
							id: 'AboutItem3',
							text: '人工智慧數據工程設計',
							link: '/about/ai-data-engineering-design',
						},
					],
				},
			],
		};
	},
};
</script>

<style>
html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

#app {
	width: 100%;
	min-height: 100%;
	padding-bottom: 209px;
	font-family: '微軟正黑體';
	color: #2c3e50;
	position: relative;
}

.content-view {
	margin-bottom: 6rem;
}
</style>
