import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/news/:name_id',
		component: () => import('../views/News.vue'),
	},
	{
		path: '/news',
		component: () => import('../views/News.vue'),
	},
	{
		path: '/solution/:name_id',
		component: () => import('../views/Solution.vue'),
	},
	{
		path: '/solution',
		component: () => import('../views/Solution.vue'),
	},
	{
		path: '/case/:name_id',
		component: () => import('../views/Case.vue'),
	},
	{
		path: '/case',
		component: () => import('../views/Case.vue'),
	},
	{
		path: '/about/:name_id',
		component: () => import('../views/About.vue'),
	},
	{
		path: '/about',
		component: () => import('../views/About.vue'),
	},
	{
		path: '*',
		name: 'NotFound',
		component: () => import('../views/Home.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;
