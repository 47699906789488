<template>
	<nav class="navbar navbar-expand-lg sticky-top navbar-light">
		<div class="row w-100 m-0 p-0 justify-content-center">
			<div
				class="col-12 col-md-11 col-lg-10 col-xl-8 m-0 p-0 d-flex position-relative"
			>
				<router-link class="navbar-brand ml-4 my-1" :to="logo.link">
					<img src="../assets/logo.png" :alt="logo.alt" />
				</router-link>
				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					aria-expanded="false"
					aria-label="Toggle navigation"
					:data-target="'#' + id + 'navbarContent'"
					:aria-controls="id + 'navbarContent'"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" :id="id + 'navbarContent'">
					<ul class="navbar-nav mr-auto mt-2">
						<li
							class="nav-item"
							:class="{ dropdown: nav.type === 'dropdown' }"
							:key="id + nav.id"
							v-for="nav in navs"
						>
							<a
								class="nav-link"
								:id="id + nav.id"
								:href="nav.link"
								v-if="nav.type === 'link'"
							>
								<span>{{ nav.text }}</span>
								<br />
								<span class="subText">{{ nav.subText }}</span>
							</a>
							<a
								class="nav-link dropdown-toggle"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								:id="id + nav.id"
								:href="nav.link"
								v-if="nav.type === 'dropdown'"
							>
								<span>{{ nav.text }}</span>
								<br />
								<span class="subText">{{ nav.subText }}</span>
							</a>
							<div
								class="dropdown-menu"
								:aria-labelledby="id + nav.id"
								v-if="nav.type === 'dropdown'"
							>
								<router-link
									class="dropdown-item"
									:to="item.link"
									:key="id + item.id"
									v-for="item in nav.value"
								>
									{{ item.text }}
								</router-link>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'NavBar',
	props: {
		id: {
			type: String,
			default() {
				return '';
			},
		},
		logo: {
			type: Object,
			default() {
				return {
					link: '#',
					alt: '',
				};
			},
		},
		navs: {
			type: Array,
			default() {
				return [];
			},
		},
	},
};
</script>

<style scoped>
.navbar {
	background-color: rgba(255, 255, 255, 0.9);
	border-bottom: solid 2px #ff5722;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.navbar::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	backdrop-filter: blur(2px);
	z-index: -1;
	margin-bottom: -1px;
}

.navbar-toggler {
	position: absolute;
	right: 0;
	height: 60px;
}

.nav-link,
.dropdown-item {
	font-size: 1.2rem;
	line-height: 1rem;
}

.subText {
	margin-top: -1rem;
	font-size: 0.8rem;
}

a {
	font-weight: bold;
}
</style>
