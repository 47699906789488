<template>
	<div class="footer container-fluid text-center">
		<div class="row justify-content-center pt-5">
			<div class="col-12 col-md-10 col-lg-9 col-xl-8">
				<div class="row justify-content-center align-items-end">
					<div class="col-auto">
						<img src="../assets/logo_v.png" alt="" />
					</div>
					<div class="col-auto ml-3">
						<div class="row">
							<font-awesome-icon class="mr-2" :icon="['fas', 'phone-alt']" />
							<span>+886-2-2715-7568</span>
						</div>
						<div class="row">
							<font-awesome-icon class="mr-2" :icon="['fas', 'envelope']" />
							<span>sales@tomatotek.com</span>
						</div>
						<div class="row">
							<font-awesome-icon
								class="mr-2"
								:icon="['fas', 'map-marker-alt']"
							/>
							<span>台北市復興北路99號15樓</span>
						</div>
					</div>
				</div>
				<hr class="w-75" />
			</div>
		</div>
		<div class="row copyright">
			<div class="col-12 py-2 pb-4">
				<span>Copyright © </span>
				<span class="text-primary-tmt">tomato</span>
				<span class="text-secondary-tmt">TEK</span>
				<span> 2017-{{ currentYear }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	props: {
		id: {
			type: String,
			default() {
				return '';
			},
		},
	},
	data() {
		return {
			currentYear: 2017,
		};
	},
	created() {
		this.currentYear = new Date().getFullYear();
	},
};
</script>

<style scoped>
.footer {
	background-color: #e7e6e6;
	position: absolute;
	bottom: 0;
	left: 0;
}
.copyright {
	background-color: #e7e6e6;
}
.company-text {
	font-weight: bold;
	font-size: 1.75rem;
}
.contact-text {
	font-size: 1.1rem;
}
.text-primary-tmt {
	color: #ff5722;
}
.text-secondary-tmt {
	color: #03c967;
}
</style>
